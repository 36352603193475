import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { loginActions } from '../store/login-slice';
import { useDispatch, useSelector } from "react-redux";
function Return() {
    const location = useLocation();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(() => {
       
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get('code');
        if (code) {
            try{
            fetch(`https://${process.env.REACT_APP_AWS_DOMAIN}oauth2/token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: `grant_type=authorization_code&client_id=${process.env.REACT_APP_AWS_CLIENT_ID}&code=${code}&redirect_uri=${encodeURIComponent(process.env.REACT_APP_REDIRECT_URL)}`
            })
                .then(response => response.json())
                .then(data => {
                    console.log(data)
                    const access_token = data.access_token;
                    localStorage.setItem('access_token', access_token);
                    dispatch(loginActions.setLogin());
                    navigate('/dashboard')
                    //   history.push('/dashboard')

                })
                .catch(error => {
                    console.error('Error:', error)
                    navigate('/')
                });
            }catch(e){
                console.log(e)
            }
        }

   

    }, [])

    return (
        <div>Return</div>
    )
}

export default Return